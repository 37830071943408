.cfra-ag-grid {
    .no-right-padding {
        padding-right: 0px !important;
    }

    .no-left-padding {
        padding-left: 0px !important;
    }

    .horizontal-padding-14 {
        padding-left: 14px;
        padding-right: 14px;
    }
}
