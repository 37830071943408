.cfra-ag-grid {
    .link-wrap-two-lines {
        line-height: 1.2;
        p,
        a {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            white-space: pre-wrap;
        }
    }

    .ellipsis-one-line {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .wrap-two-lines > .ag-cell-wrapper > span {
        line-height: 1.2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
    }

    // fix unable to change the cursor on ag-grid
    .ag-root-wrapper {
        cursor: unset;
    }

    .ag-theme-alpine.grid-theme-v2 {
        // header
        --ag-header-foreground-color: #5a5a5a;

        .ag-header-cell-text {
            font-size: 13.5px;
            font-weight: 500;
            line-height: 20px;
        }

        .ag-header {
            border-top: none;
            border-bottom: 1px solid #aaaaaa;
        }

        .ag-header-row {
            .start-left {
                padding-left: 0;
            }

            .document-icon {
                padding: 0px;
            }
        }

        // rows
        .ag-row {
            font-weight: 400;
            color: #3c3c3c;
            font-size: 13.5px;

            .bold-value {
                font-weight: 600;
            }

            .start-left {
                padding-left: 0;
            }

            .document-icon {
                display: flex;
                align-items: center;
            }

            .ag-cell {
                display: flex;
                align-items: center;

                .ag-cell-wrapper {
                    width: 100%;
                }
            }
        }

        .ag-root ::-webkit-scrollbar {
            width: 15px;
            height: 15px;
        }

        .ag-root ::-webkit-scrollbar-button {
            height: 0px;
        }

        .ag-root ::-webkit-scrollbar-thumb {
            min-height: 51%;
            background-color: #e4e8f4;
            border-radius: 30px;
        }
    }
    .ag-theme-alpine.bc-grid-theme {
        --ag-cell-horizontal-padding: 15px;

        // header
        --ag-header-foreground-color: #5a5a5a;

        // resize elements in header
        .ag-header-container .ag-header-cell-resize {
            width: 5px;
        }

        .ag-header-cell .ag-header-cell-resize::after {
            background-color: #fff;
            height: 100%;
            top: 0;
            width: 100%;
        }

        .ag-header-cell-text {
            font-size: 13.5px;
            font-weight: 500;
            line-height: 20px;
        }

        .ag-header {
            border-top: none;
            border-bottom: 1px solid #aaaaaa;
        }

        .ag-body-horizontal-scroll {
            @media (min-width: 1320px) {
                display: none;
            }
        }

        // rows
        .ag-row {
            font-weight: 400;
            color: #3c3c3c;
            font-size: 13.5px;
            border-bottom: 0px;

            .ag-cell {
                display: flex;
                align-items: center;
            }

            .bold-value {
                font-weight: 500;
                font-family: 'GraphikSemibold';
            }

            a {
                color: #002b5a;
            }
        }

        .ag-root ::-webkit-scrollbar {
            width: 15px;
            height: 15px;
        }

        .ag-root ::-webkit-scrollbar-button {
            height: 0px;
        }

        .ag-root ::-webkit-scrollbar-thumb {
            min-height: 51%;
            background-color: #e4e8f4;
            border-radius: 30px;
        }
    }

    .ag-theme-alpine.cp-grid-theme {
        --ag-cell-horizontal-padding: 15px;
        // header
        --ag-header-foreground-color: #5a5a5a;

        // resize elements in header
        .ag-header-container .ag-header-cell-resize {
            width: 5px;
        }

        .ag-header-cell .ag-header-cell-resize::after {
            background-color: #fff;
            height: 100%;
            top: 0;
            width: 100%;
        }

        .ag-header-cell-text {
            font-size: 13.5px;
            font-weight: 500;
            line-height: 20px;
        }

        .ag-header {
            border-top: none;
            border-bottom: 1px solid #aaaaaa;
        }

        .ag-body-vertical-scroll {
            display: flex;
        }
        // rows
        .ag-row {
            font-weight: 400;
            color: #3c3c3c;
            font-size: 13.5px;
            border-bottom: 0px;

            .ag-cell {
                display: flex;
                align-items: center;
                border: 0px;
                border-bottom: 1px dotted #e4e4e4;

                .ag-cell-wrapper {
                    width: 100%;
                }
            }

            .link-wrap {
                word-break: break-word;
                white-space: normal;
                line-height: 1;
                border-bottom: 1px dotted #e4e4e4;
            }

            .text-border-wrap {
                white-space: normal;
                word-break: break-word;
                line-height: 1;
                border-bottom: 1px dotted #e4e4e4;
            }

            .no-cell-borders {
                border: none;
            }

            .link-wrap-two-lines {
                line-height: 1;
                p,
                a {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    white-space: pre-wrap;
                }
            }

            .bold-value {
                font-weight: 500;
                font-family: 'GraphikMedium';
                text-align: right;
            }

            .ag-cell-focus {
                border: none;
                border-bottom: 1px dotted #e4e4e4;
            }

            a {
                color: #002b5a;
            }
        }

        .ag-root ::-webkit-scrollbar {
            width: 15px;
            height: 15px;
        }

        .ag-root ::-webkit-scrollbar-button {
            height: 0px;
        }

        .ag-root ::-webkit-scrollbar-thumb {
            min-height: 51%;
            background-color: #e4e8f4;
            border-radius: 30px;
        }
    }

    .ag-theme-alpine.ag-row-no-border {
        .ag-row {
            .ag-cell {
                border: none;
            }
        }
    }
}
