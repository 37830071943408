.cfra-ag-grid {
    .ag-theme-alpine.grid-theme-v2,
    .ag-theme-alpine.cp-grid-theme,
    .ag-theme-alpine.grid-theme-v4,
    .ag-theme-alpine.bc-grid-theme {
        .ag-header-cell {
            .ag-sort-none-icon {
                opacity: 0; /* hide unsorted icon */
            }
        }

        .ag-header-cell:hover {
            .ag-sort-none-icon {
                opacity: 1; /* show unsorted icon on hover */
            }
        }

        .ag-header-cell .ag-header-cell-sorted-asc .ag-header-cell-label .ag-icon,
        .ag-header-cell .ag-header-cell-sorted-desc .ag-header-cell-label .ag-icon {
            opacity: 1; /* always show sorted icons */
        }
    }
}
