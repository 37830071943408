.cfra-ag-grid {
    .ag-theme-alpine.ag-panel-top-position-fixed {
        .ag-root-wrapper {
            // cover case when menu height is more then grid height (like on watchlist page)
            overflow: visible;
        }

        .ag-panel {
            min-height: 300px;
            top: 34px !important;
        }
    }
}
